<template>
	<div class="servicedetail">
		<!-- header -->
		<homePage :urlFlag="2"></homePage>
        <!-- banner -->
        <div class="container-fluid bannerBox bannerImg">
            <img src="@/assets/img_banner_service_6.png">
        </div>
        <div class="container newsList">
            <div class="row">
                <div class="col-md-9 article">
                    <h1>文创中心</h1>
                    <div class="articleDetail">
                        <img src="@/assets/img_service_text_6.png" alt="">
                        <p>我们为客户提供从创新研究、用户调研、产品设计到品牌建构的全面商业解决方案。</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contactUs">
                        <div class="contactTitle">联系我们</div>
                        <div class="contactName">Fisher YU</div>
                        <div class="person-contact">
                            <div>
                                <div class="icon font_family icon-icon_phone_white"></div><div>servicepinkoi</div>
                            </div>
                            <div>
                                <div class="icon font_family icon-icon-mail"></div><div>srl-market@srleader.com</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             <!-- 成功案例 -->
            <div class="cases">成功案例</div>
            <div class="allCases">
                <div class="case-box-hover"><img src="@/assets/img_service_6_1.png" alt=""><p>文创衍生品开发</p></div>
                <div class="case-box-hover"><img src="@/assets/img_service_6_2.png" alt=""><p>IP形象设计</p></div>
                <div class="case-box-hover"><img src="@/assets/img_service_6_3.png" alt=""><p>文化创意策划</p></div>
                <div class="case-box-hover"><img src="@/assets/img_service_6_4.png" alt=""><p>工艺美术品</p></div>
                <div class="case-box-hover"><img src="@/assets/img_service_6_5.png" alt=""><p>组织文化艺术交流活动</p></div>
                <div class="case-box-hover"><img src="@/assets/img_service_6_6.png" alt=""><p>企业形象策划</p></div>
            </div>
        </div>
		<afterbody></afterbody>
	</div>
</template>

<script>
// @ is an alias to /src
import homePage from "@/components/homePage/homePage.vue";
import afterbody from "@/components/afterbody/afterbody.vue";

export default {
	name: "servicepinkoi",
	components: {
		homePage,
		afterbody
	},
	data() {
		return {
		}
	},
	methods: {
	},
	mounted() {
	},
};
</script>

<style scoped>
@import "servicepinkoi.css";
</style>
